import React from 'react'


const Header = () => {




  function myFunction() {
    var x = document.getElementById("ddlViewBy");
    var value = x.value;
    var text = x.options[x.selectedIndex].text
    console.log(value);
   
  }
// function onChange() {
//   var value = e.value;
//   var text = e.options[e.selectedIndex].text;
//   console.log(value, text);
// }
// e.onchange = onChange;
// onChange();

  return (
    <header className="App-header py-2" >
    {/* {search} */}

    {/* <select id="ddlViewBy" onChange={()=>{myFunction()}}>
      <option value="1">test1</option>
      <option value="2" selected="selected">test2</option>
     
    </select> */}
    
    <nav className="navbar navbar-expand-lg bg-transparent p-0">
  <div className="container">
    <a className="navbar-brand text-decoration-none fs-4 text-white fw-bold" href="#">IMAGEWALA</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <a className="nav-link text-white active" aria-current="page" href="#">Home</a>
            </li>
            <li className="nav-item">
            <a className="nav-link text-white" href="#">About</a>
            </li>
            
            <li className="nav-item">
            <a className="nav-link text-white" href="#">Contact Us</a>
            </li>
            
        </ul>
        </div>
    </div>
    </nav>
        {/* <button className='btn btn-primary' onChange={fetchImage}>Search</button> */}
        
        </header>
  )
}

export default Header