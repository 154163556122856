import React from 'react'

const Footer = () => {
  return (
    <footer className='footer bg-dark py-3'>
        <div className="container text-center">
            <p className='text-white m-0'> © <strong>2022 ImageWala</strong> All Rights Reserved.</p>
        </div>
    </footer>
  )
}

export default Footer