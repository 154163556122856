import logo from './logo.svg';
import './App.css';
import './style/style.scss'

import axios from 'axios';
import { StrictMode, useState, useEffect } from "react";

import Header from './components/Header';
import Footer from './components/Footer';
import {saveAs} from "file-saver";

function App() {
 const [data,setData] = useState([]);
 const [search,setSearch] = useState("Search");
 const [singleValue,setSingleValue] = useState(" ");
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);
 const [HeroImage, SetHeroImage] = useState("https://smartwatermagazine.com/sites/default/files/styles/thumbnail-830x455/public/fields_punjab-wikipedia.jpg?itok=nBRP46zg");

//  const searchData = (e) => {
//   let innerData = e.target.value;
//   setSearch(innerData);
//   axios.get(`https://api.unsplash.com/search/photos?page=1&query=${innerData}&client_id=mWZ1Vmf1hyaEc1jhy3mwLiVcTHMT3yv1cX9nM40d5yI`)
//   .then((response)=> {
//     setData(response.data.results)
//     console.log(response.data)
//   })
  
// }

// fetch('https://dummyjson.com/products/1')
// .then(res => res.json())
// .then(json => console.log(json))




const mainData = (e) => {
  let innerData = e.target.value;

  console.log(innerData);

  setSearch(innerData);

}

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    // 👇 Get input value
 
    searchData();
  }
};

const searchData = async () => {
  
  try {
    const response = await axios.get(`https://api.unsplash.com/search/photos?page=1&query=${search}&client_id=mWZ1Vmf1hyaEc1jhy3mwLiVcTHMT3yv1cX9nM40d5yI`);
    console.log(response.data.results[0].urls);
    setData(response.data.results);
    SetHeroImage(response.data.results[0].urls.regular);
  } catch (err) {
    const errorMessage = "Error: " + err.message;
    setError(errorMessage);
    console.log(errorMessage);
  } finally {
    setIsLoading(false);
  }
}


useEffect(() => {
  searchData();
}, []);

//  const fetchImage = () => {
//   console.log("hello brother")
 
//  }

const handleClick = ()=>{
  let url = singleValue;
  saveAs(url, "ImageWala");
 }
 
if (isLoading) return "Loading...";
if (error) return error;

  return (
    <div className="App">
      <Header/>
      <div className="mainbanner d-flex align-items-center" 
      
      style={{ backgroundImage: `url(${HeroImage})` }} 
      >
        <div className="container">
          <div className="row justify-content-center position-relative">
            <div className="col-6">
            <input type="text" placeholder='Search Image' className='shadow-none form-control'  
            onChange={mainData}
            onKeyDown={handleKeyDown}

            />  
            </div>
          </div>
        </div>
      </div>
      <div className="container py-4">
      <div className='row'>
        {
          data.map((value,id)=>{
              return <div className='col-lg-2 col-md-3' key={id}>
                  <div className='card' title={`Width:${value.width}px * Height: ${value.height}px`}  onClick={() => setSingleValue(value.urls.full)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <img src={value.urls.regular} alt="" className='img-fluid ' width="600" height="400"/>
                     
                  </div>
                </div>
               
          })
          }
          </div>
         



            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                  <button className="btn btn-primary"  onClick={handleClick}>Dowload image</button>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                        <img src={singleValue} alt="" className='img-fluid'/>

                        
                  </div>
                  <div className="modal-footer">
                       
                      
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
        
        </div>
        <Footer />
    </div>
  );
}

export default App;
